.products-table-input {
	display: block;
}

.large-icon {
	margin-top: 25px;
	font-size: 300px;
	width: 100%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.max-width {
	width: 100%;
}
