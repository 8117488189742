.chip {
  align-items: center;
  background-color: $chip-bg;
  border: 0;
  border-radius: ($chip-height * 0.5);
  color: $chip-color;
  display: inline-flex;
  font-size: $chip-font-size;
  font-weight: $chip-font-weight;
  height: $chip-height;
  justify-content: center;
  line-height: $chip-line-height;
  padding-right: $chip-padding-x;
  padding-left: $chip-padding-x;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  &:empty {
    display: none;
  }

  .close {
    font-size: inherit;
    line-height: inherit;
    margin-right: ($chip-inner-spacer-x - $chip-padding-x);
    margin-left: $chip-inner-spacer-x;
    min-height: $material-icon-size;
    min-width: $material-icon-size;
    order: 1;
  }
}

@each $color, $values in $theme-colors {
  .chip-#{$color} {
    background-color: theme-color($color);
    color: color-yiq(theme-color($color));
  }
}

// Misc

.chip-action {
  @include transition-standard(background-color, box-shadow);

  @include active-focus-hover {
    box-shadow: map-get($chip-elevation-shadow-hover, shadow);
    color: $chip-color;
    text-decoration: none;
  }

  &:active {
    background-color: $chip-bg-active;
  }

  &:focus {
    outline: 0;
  }
}

.chip-icon {
  @include text-truncate;

  align-items: center;
  background-color: $chip-icon-bg;
  border-radius: ($chip-height * 0.5);
  color: color-yiq($chip-icon-bg);
  display: inline-flex;
  flex-shrink: 0;
  font-size: $chip-icon-font-size;
  font-style: normal;
  font-weight: $chip-font-weight;
  height: $chip-height;
  justify-content: center;
  margin-right: ($chip-inner-spacer-x * 2);
  margin-left: ($chip-padding-x * -1);
  order: -1;
  text-align: center;
  width: $chip-height;
}

.chip-img {
  border-radius: ($chip-height * 0.5);
  flex-shrink: 0;
  height: $chip-height;
  margin-right: ($chip-inner-spacer-x * 2);
  margin-left: ($chip-padding-x * -1);
  order: -1;
  width: auto;
}
