.react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
}

.panel.htmx-swapping {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.dark.active {
  background-color: #111;
}

.bg-black {
  background-color: #111111 !important;
}

.register-box {
  width: 750px;
}

.fade-enter {
  opacity: 0.01;
  transition: opacity .5s ease-in;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}

.fade-leave {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
}


th {
	background-color: white;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
}

.react-contextmenu-item {
    width: 200px;
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item:hover {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.inner h4 {
  font-weight: bold;
  font-size: 25px;
}

.popover{
    max-width: 100%;
}

.ui-tooltip {
  display: none;
}

.shipping-address {
  font-size: 16px;
  color: #444444;
}

.sortable { list-style-type: none; margin: 0; padding: 0; width: 100%; }
.sortable div { margin-left: 24px; cursor: pointer; margin-top: 10px; }
.sortable li { background: white; border: none; }
.sortable li span { position: absolute; margin-top: 2px; }
.sortable input[type=checkbox] { margin: 0; vertical-align: middle; }

.shipping-address b {
  font-size: 18px;
}

.input-xs {
  max-width: 80px;
}

.cart-details {
  /*font-size: 14px;*/
}
.checkbox-inline {
  display: inline;
}

.small-rows > tbody > tr > td {
  padding: 5px;
}

.small-rows > tbody > tr > th {
  padding: 5px;
}

.new-booking-item a {
  background-color: #71113F !important;
  color: white !important;
}

.boolean {
  width: auto;
}

.content {
  max-width: 1200px;
}

.label-big label {
  font-weight: bold;
  font-size: 14px;
}

table .form-group {
  margin-bottom: 0px;
}

.table-overlay {
  color: black;
  font-size: 10px;
  border-radius: 3px;
  border: 1px solid black;
}

.white-overlay {
  background-color: rgba(255, 255, 255, 0.7);
}

.red-overlay {
  background-color: rgba(242, 222, 222, 0.7);
}

.yellow-overlay {
  background-color: rgba(252, 248, 227, 0.7);
}

.green-overlay {
  background-color: rgba(223, 240, 216, 0.7);
}

.blue-overlay {
  background-color: rgba(217, 237, 247, 0.7);
}

.table-overlay .table-booked-name {
  text-align: center;
}

.table-overlay .table-booked-info {
  text-align: center;
}

.table-overlay .table-booked-tables {
  text-align: center;
}

.checkbox-inline label {
  margin-bottom: 0px;
  font-weight: normal;
}

.table-responsive {
  overflow-x: auto;
}

.table-responsive input {
  min-width: 50px;
}

.runmode-indicator {
  color: red;
  padding-left: 20px;
}

.no-padding {
  padding: 0 !important;
}

.wrapper {
  padding-top: 200px;
}

.left-side {
  padding-top: 200px;
}

@media (min-width: 768px) {
  .wrapper {
    padding-top: 0;
  }
  .left-side {
    padding-top: 0;
  }

}

.checkbox-item {
  list-style: none;
}

.booking-preview {
  font-size: 12px;
}

#new_booking .form-group {
  margin-bottom: .2rem;
}

.small-col-left {
  padding-right: 5px;
}

.small-col-right {
  padding-left: 5px;
}

.spacer {
  margin-top: 5px;
}

.bg-taskbar {
  background-color: #71113F !important;
}

.bg-taskbar .active {
  // background-color: #85144B !important;
}

.logo {
  background-color: #71113F !important;
}

// .obs-navbar a {
//   color: black !important;
// }

// .navbar .nav a {
//   color: #FFFFFF !important;
// }

.skin-black .navbar .sidebar-toggle .icon-bar {
    background: none repeat scroll 0 0 white !important;
}

.tb-nav a:hover {
  // background-color: #B11B63 !important;
}

.user-body a {
  color: #DDDDDD !important;
}

.nav a {
  font-size: 14px !important;
  color: black;
}

// .dropdown-menu a {
//   background-color: #71113F !important;
//   color: #FFFFFF !important;;
// }
//
// .dropdown-menu li {
//   background-color: #71113F !important;
//   color: #FFFFFF !important;
// }

.logo {
  font-size: 18px !important;
}

.menu a {
  font-size: 14px !important;
}

.navbar-right .dropdown-toggle {
  padding-bottom: 10px !important;
}

.user-footer a {
  color: #DDDDDD !important;
}

.bg-taskbar .active a {
  background-color: #85144B !important;
  color: white;
}

.glyphicon {
	margin-right: 5px;
}

.navigation {
	padding: 0px;
}

.checkbox_container {
    display: inline;
}

.panel .inline-label {
  margin-top: 0px;
}

/* ____________________ Login Screen ____________________ */

body#login-screen {
  background-color: #570017;
}

#horizon {
  left: 0;
  top: 45%;
  height: 1px;
  width: 100%;
  background-color: #570017;
  display: block;
  overflow: visible;
  position: absolute;
  text-align: left;
  visibility: visible;
}

#horizon-banner {
  left: 0;
  top: -450;
  height: 900;
  width: 100%;
  position: absolute;
  visibility: visible;

  background-image:         linear-gradient(to bottom, #570017 1%, #860024 50%, #570017 99%);
  background-image:      -o-linear-gradient(to bottom, #570017 1%, #860024 50%, #570017 99%);
  background-image:    -moz-linear-gradient(to bottom, #570017 1%, #860024 50%, #570017 99%);
  background-image: -webkit-linear-gradient(to bottom, #570017 1%, #860024 50%, #570017 99%);
  background-image:     -ms-linear-gradient(to bottom, #570017 1%, #860024 50%, #570017 99%);

  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.01, #570017),
    color-stop(0.50, #860024),
    color-stop(0.99, #570017)
  );
}

#horizon-logo {
  left: 50%;
  top: -24px;
  height: 54px;
  width: 190px;
  margin-left: -350px;
  position: absolute;
  visibility: visible;
}

/* "No IE" Logo für IE < 10 */
#horizon-noie {
  left: 50%;
  top: -20px;
  height: 52px;
  width: 190px;
  margin-left: 220px;
  position: absolute;
  visibility: visible;
}

#center-container {
  left: 50%;
  top: -86px;
  height: 172px;
  width: 300px;
  margin-left: -150px;
  position: absolute;
  visibility: visible;
}

.login-box {
  border: 0px solid;
  padding: 20px;
  background: none repeat scroll 0 0 #eee;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.7), 0 0 40px rgba(0, 0, 0, 0.5) inset;

  width: 360px;
  margin: 7% auto;

  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;
}

.login-box .login-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
  color: white;
}

.login-box input, .login-box textarea {
  width: 244px;
}

.login-box input[type="checkbox"] {
  margin-top: 7px;
  width: 20px;
  float: right;
}

.login-box label {
  margin-bottom: 2px;
}

.login-box #remember_login label {
  margin-top: 4px;
  float: right;
}

.login-box form {
  margin: 0;
}

.login-box h4 {
  margin-left: 1px;
  margin-top: 2px;
}

.login-box-body {
  padding: 20px;
  border-top: 0;
  color: #666;
}

/*____________________________________________________*/





.runmode-indicator {
  color: red;
  padding-left: 20px;
}

.bottom-spacer {
  padding-bottom: 6px;
}

.box-header {
  padding-bottom: 6px;
}

#activitybox {
  vertical-align: middle;
  text-align: center;
}

tr.clickable {
  cursor: pointer;
}

/* ___ Icon in Navi rechts darstellen (bspw. Reservierungen im BI) ___ */
.right-icon i {
  float: right;
  margin-top: 6px;
  margin-right: 0px;
}

td.currency {
    text-align: right;
}

tr.counter {
    text-align: right;
}

td.counter {
    text-align: right;
}

td .linetitle {
    text-align: right;
    font-weight: bold;
}


/*
Charts (Morris.ja)
*/

.chart-house-analysis {
  height: 180px;
}


/*
Belegungsplan Darstellung
*/

.plan-all {
  position: absolute;
  text-align: center;
  overflow: hidden;
}

.plan-title {
  font-size: 18px;
  font-weight: bold;
}

.plan-ttt {
  font-size: 60%;
  height: 16px;
  background-color: #FFF;
  color: #FFF;
  font-weight: bold;
  line-height: 14px;
}

.plan-table {
}

.plan-bt {
  margin: 1px;
  border: 1px solid #6A6A6A;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #0088CC, #0044CC);
}

a .plan-bt {
  color: #fff;
}

a:hover .plan-bt  {
  /*margin: 0;*/
  /*border: 2px solid #F33;*/
  color: #FFF;
  text-decoration: none;
  background-image: linear-gradient(to bottom, #0066bb, #0033bb);
}


.printonly {
  display: none;
}



/* _________________ Tabellen _________________ */

/*thead,
tr.trhead td,
tr.headline {
  background-image: linear-gradient(bottom, #e5e5e5 28%, #ffffff 100%);
  background-image: -o-linear-gradient(bottom, #e5e5e5 28%, #ffffff 100%);
  background-image: -moz-linear-gradient(bottom, #e5e5e5 28%, #ffffff 100%);
  background-image: -webkit-linear-gradient(bottom, #e5e5e5 28%, #ffffff 100%);
  background-image: -ms-linear-gradient(bottom, #e5e5e5 28%, #ffffff 100%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.28, #e5e5e5),
    color-stop(1, #ffffff)
  );
}

tr.trhead td {
  font-weight: bold;
  border-top: 2px solid #aaa;
}

th.counter, td.counter, th.currency, td.currency, th.buttons, td.buttons {
  text-align: right;
}

th.width-c, td.width-c {
  width: 10em;
}

th.width-a, td.width-a {
  width: 5em;
}

tr.summary td {
  font-weight: bold;
  background-color: #eee !important;
  border-bottom: 2px solid #aaa;
}

tr .row-fluid [class*="span"] {
  min-height: 20px;
}*/


/* orders:index */

th #th-order-index-order-tax {
  text-align: right;
}

// tr #th-order-index-order-created-at {
//   width: 13em;
//   min-width: 13em;
// }
//
// tr #th-order-index-order-tag {
//   min-width: 7em;
// }
//
// tr #th-order-index-shipping-method {
//   width: 10em;
// }
//
// tr #th-order-index-payment-method {
//   width: 7.5em;
// }

tr.order-state-8 .currency, tr.order-state-9 .currency {
  text-decoration: line-through;
}


/* Reservierungskalender */

.booking-calendar h3 {
  margin: 0;
}

.booking-calendar tr.alternate-row {
  background: none repeat scroll 0 0 #F7F7F7;
}

.booking-calendar .thday {
  width: 8em;
}

.booking-calendar .thbtime {
  width: 12em;
}

.well-small {
  padding: 6px 10px;
  margin-bottom: 15px;
}

.reservations-preview {
  background-color: #cccccc;
  font-size: 15px;
  font-weight: bold;
}

a.disabled {
    pointer-events: none;
}

.opening-time {
    margin-bottom: 10px;
}
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

#sortable { list-style-type: none; margin: 0; padding: 0; }
#sortable li {
	margin: 0 3px 3px 3px;
	padding: 0.4em;
	padding-left: 1.5em;
	font-size: 1.4em;
	height: 50px;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

 .sidebar-sticky {
   background-color: #f9fafc;
 }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    // position: -webkit-sticky;
    // position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 48px; /* Space for fixed navbar */
  }
}

/*
 * Navbar
 */

 .navbar {
   z-index: 10000;
 }

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Component: Box
 * --------------
 */
.box {
  margin: 10px;
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.box.box-primary {
  border-top-color: #3c8dbc;
}
.box.box-info {
  border-top-color: #00c0ef;
}
.box.box-danger {
  border-top-color: #dd4b39;
}
.box.box-warning {
  border-top-color: #f39c12;
}
.box.box-success {
  border-top-color: #00a65a;
}
.box.box-default {
  border-top-color: #d2d6de;
}
.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
  display: none;
}
.box .nav-stacked > li {
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
}
.box .nav-stacked > li:last-of-type {
  border-bottom: none;
}
.box.height-control .box-body {
  max-height: 300px;
  overflow: auto;
}
.box .border-right {
  border-right: 1px solid #f4f4f4;
}
.box .border-left {
  border-left: 1px solid #f4f4f4;
}
.box.box-solid {
  border-top: 0;
}
.box.box-solid > .box-header .btn.btn-default {
  background: transparent;
}
.box.box-solid > .box-header .btn:hover,
.box.box-solid > .box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-default {
  border: 1px solid #d2d6de;
}
.box.box-solid.box-default > .box-header {
  color: #444444;
  background: #d2d6de;
  background-color: #d2d6de;
}
.box.box-solid.box-default > .box-header a,
.box.box-solid.box-default > .box-header .btn {
  color: #444444;
}
.box.box-solid.box-primary {
  border: 1px solid #3c8dbc;
}
.box.box-solid.box-primary > .box-header {
  color: #ffffff;
  background: #3c8dbc;
  background-color: #3c8dbc;
}
.box.box-solid.box-primary > .box-header a,
.box.box-solid.box-primary > .box-header .btn {
  color: #ffffff;
}
.box.box-solid.box-info {
  border: 1px solid #00c0ef;
}
.box.box-solid.box-info > .box-header {
  color: #ffffff;
  background: #00c0ef;
  background-color: #00c0ef;
}
.box.box-solid.box-info > .box-header a,
.box.box-solid.box-info > .box-header .btn {
  color: #ffffff;
}
.box.box-solid.box-danger {
  border: 1px solid #dd4b39;
}
.box.box-solid.box-danger > .box-header {
  color: #ffffff;
  background: #dd4b39;
  background-color: #dd4b39;
}
.box.box-solid.box-danger > .box-header a,
.box.box-solid.box-danger > .box-header .btn {
  color: #ffffff;
}
.box.box-solid.box-warning {
  border: 1px solid #f39c12;
}
.box.box-solid.box-warning > .box-header {
  color: #ffffff;
  background: #f39c12;
  background-color: #f39c12;
}
.box.box-solid.box-warning > .box-header a,
.box.box-solid.box-warning > .box-header .btn {
  color: #ffffff;
}
.box.box-solid.box-success {
  border: 1px solid #00a65a;
}
.box.box-solid.box-success > .box-header {
  color: #ffffff;
  background: #00a65a;
  background-color: #00a65a;
}
.box.box-solid.box-success > .box-header a,
.box.box-solid.box-success > .box-header .btn {
  color: #ffffff;
}
.box.box-solid > .box-header > .box-tools .btn {
  border: 0;
  box-shadow: none;
}
.box.box-solid[class*='bg'] > .box-header {
  color: #fff;
}
.box .box-group > .box {
  margin-bottom: 5px;
}
.box .knob-label {
  text-align: center;
  color: #333;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0.3em;
}
.box > .overlay,
.overlay-wrapper > .overlay,
.box > .loading-img,
.overlay-wrapper > .loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}
.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}
.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}
.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}
.collapsed-box .box-header.with-border {
  border-bottom: none;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion {
  margin-right: 5px;
}
.box-header > .box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}
.box-header > .box-tools [data-toggle="tooltip"] {
  position: relative;
}
.box-header > .box-tools.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}
.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  color: #97a0b3;
}
.open .btn-box-tool,
.btn-box-tool:hover {
  color: #606c84;
}
.btn-box-tool.btn:active {
  box-shadow: none;
}
.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 20px;
}
.no-header .box-body {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.box-body > .table {
  margin-bottom: 0;
}
.box-body .fc {
  margin-top: 5px;
}
.box-body .full-width-chart {
  margin: -19px;
}
.box-body.no-padding .full-width-chart {
  margin: -9px;
}
.box-body .box-pane {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
}
.box-body .box-pane-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}
.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: #ffffff;
}
.chart-legend {
  margin: 10px 0;
}
@media (max-width: 991px) {
  .chart-legend > li {
    float: left;
    margin-right: 10px;
  }
}
.box-comments {
  background: #f7f7f7;
}
.box-comments .box-comment {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}
.box-comments .box-comment:before,
.box-comments .box-comment:after {
  content: " ";
  display: table;
}
.box-comments .box-comment:after {
  clear: both;
}
.box-comments .box-comment:last-of-type {
  border-bottom: 0;
}
.box-comments .box-comment:first-of-type {
  padding-top: 0;
}
.box-comments .box-comment img {
  float: left;
}
.box-comments .comment-text {
  margin-left: 40px;
  color: #555;
}
.box-comments .username {
  color: #444;
  display: block;
  font-weight: 600;
}
.box-comments .text-muted {
  font-weight: 400;
  font-size: 12px;
}

.brand-image {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-height: 40px;
}

.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);

  .inner {
    min-height: 90px;
    padding: 10px;

    color: white;
  }

  .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255,255,255,0.8);
    display: block;
    z-index: 10;
    background: rgba(0,0,0,0.1);
    text-decoration: none;
  }

  .icon {
    transition: all 0.3s linear;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0,0,0,0.15);
  }
}

.fc-title {
  color: white;
  font-size: 12px;
}

.tab-pane {
  margin-top: 5px;
}

@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background: #FFF;
    -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb {
    background: #CCC;
    -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:hover {
    background: #AAA;
}
::-webkit-scrollbar-thumb:active {
    background: #888;
    -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.3);
}

html, .navdrawer-content {
  scrollbar-color: #CCC #FFF;
  scrollbar-width: thin;
}

.btn.active {
  border: 2px solid #333;
}
